import { useState, useEffect } from "react";

interface ItemProps {
    key: string;
    value: any;
}

const processData = (text: string) => {
    const items: ItemProps[] = []
    const res = JSON.parse(text)
    Object.entries(res).forEach((entry) => {
        const [key, value] = entry;
        items.push({ key, value })
    });
    return items
}


const HelloWorld = () => {
    const [data, setData] = useState<ItemProps[]>([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => fetch("/helloworld")
        .then((res) => res.text())
        .then(processData)
        .then((data) => {
            setData(data)
        })

    return <ul>
        {data?.map((item) => {
            return <li>{item.key} : {item.value}</li>
        }
        )}
    </ul>
}

export default HelloWorld;